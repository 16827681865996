<template>
    <div>
        <v-app-bar dense absolute text class="fixedToolbar" style="z-index: 2;">
            <v-toolbar-title v-bind:class="{'headline grey-text': !drawer, 'padLeft headline grey-text': drawer }">Incomes</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-tooltip left v-if="!speedDial">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click.prevent="$router.push({ path: '/dashboard/addTransaction/income' })"
                           slot="activator"
                           small
                           fab
                           text
                           middle
                           right>
                        <v-icon>fas fa-plus</v-icon>
                    </v-btn>
                </template>
                <span>Add Income</span>
            </v-tooltip>

            <!--Speed Dial-->
            <v-speed-dial v-model="speedDial"
                          :direction="direction"
                          :transition="transition">

                <template v-slot:activator>
                    <v-btn slot="activator"
                           v-model="speedDial"
                           text
                           small
                           fab>
                        <v-icon v-if="!speedDial">fas fa-ellipsis-v</v-icon>
                        <v-icon v-else>fas fa-times</v-icon>
                    </v-btn>
                </template>

                <!-- Search Button -->
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click.prevent="speedDialChanged('search')"
                               slot="activator"
                               fab
                               text
                               small
                               middle
                               right>
                            <v-icon>fas fa-search</v-icon>
                        </v-btn>
                    </template>
                    <span>Search</span>
                </v-tooltip>
                <!--End Of Search Button-->
                <!-- Filter Button -->
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click.prevent="speedDialChanged('filter')"
                               slot="activator"
                               fab
                               text
                               small
                               middle
                               right>
                            <v-icon>fas fa-filter</v-icon>
                        </v-btn>
                    </template>
                    <span>Filter</span>
                </v-tooltip>
                <!-- End Of Filter Button -->

            </v-speed-dial>
            <!--End Of Speed Dial-->

        </v-app-bar>

        <!--Card Design-->
        <!--Card Design-->
        <v-container fluid class="card-flex-toolbar noPadding ">

            <DateSkipper v-bind:type="datePickerType" v-bind:drawer="drawer" v-bind:startDate="datePeriod" v-on:updateDate="dateChanged($event)" v-on:next="nextDate()" v-on:previous="previousDate()"></DateSkipper>

            <v-layout row wrap style="height:100%" class="padContent">

                <v-flex v-bind:class="[showDataOnSide ? 'md6' : 'md12']">
                    <v-container fluid
                                 style="min-height: 0;"
                                 grid-list-lg
                                 class="padContentMin">

                        <!--Search Box-->
                        <template v-if="speedDialOptions.viewSearch && !speedDialOptions.viewFilter">

                            <Search v-on:gSearch="updateSearch"></Search>

                        </template>
                        <!--End Of Search Box-->
                        <!--No Data Message-->
                        <template v-if="itemsMinusDeleted.length === 0">
                            <NoData></NoData>
                        </template>
                        <!--End Of No Data Message-->

                        <v-layout row wrap>

                            <ListItems v-bind:liItem="{type: 'transaction',data: itemsMinusDeleted}" ref="listItem" v-on:Edit="redirectToEditPage($event)" v-on:Delete="DeleteIncome($event)" v-on:clickedItem="itemclicked($event)"></ListItems>

                        </v-layout>
                    </v-container>
                </v-flex>
                <v-flex md6 v-if="showDataOnSide && $vuetify.breakpoint.mdAndUp" style="height:100%">

                    <ListItemData v-bind:lItem="listItemData" v-on:show="adjustShow($event)" v-on:EditItem="redirectToEditPage($event)" v-on:DeleteItem="DeleteIncome($event)"></ListItemData>

                </v-flex>
            </v-layout>

            <!--End Of Card Design-->


        </v-container>


        <!-- Delete Income Dialog Box -->
        <v-layout row justify-center>
            <v-dialog v-model="deleteDialog" max-width="290">
                <v-card>
                    <v-card-title class="headline">Delete</v-card-title>
                    <v-card-text>Are you sure you want to delete <b>"{{deleteIncome.incomeName}}"</b>?</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text="text" @click.native="deleteDialog = false">No</v-btn>
                        <v-btn color="green darken-1" text="text" @click.native="DeleteIncomeAccept()">Yes</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
        <!-- End Of Delete Income Dialog Box -->

    </div>
</template>


<script>
 
  import { listItemFunctions } from '../../javascript/listItemManager'
  import { globalFunctions } from '../../javascript/globalFunctions'
    import { widgetManager } from '../../javascript/widgetManager'
    import { dateManager } from '../../javascript/dateManager'

  export default {
    components: {
        ListItems: () => import('../../components/listItem/listItems.vue'),
        NoData: () => import('../../components/noData/noData.vue'),
        Search: () => import('../../components/search/search.vue'),
        ListItemData: () => import('../../components/listItem/listItemData.vue'),
        DateSkipper: () => import('../../components/dateSkipper/dateSkipper.vue'),
    },
    props: ['drawer'],
    data() {
      return {
        direction: 'left',
        speedDial: false,
        transition: 'slide-y-reverse-transition',
        moreOptions: false,
        search: '',
        datePickerType: 'month',
        datePeriod: new Date().toISOString().substr(0, 7),
        speedDialOptions: {
          viewSearch: false,
          viewFilter: false
        },
        account: [],
        category: [],
        OneTime: false,
        Recurring: false,
        type: 'OneTime',
        addModelIncomeDate: false,
        addModelGoesOff: false,
        sameIncome: false,
        addDialog: false,
        deleteDialog: false,
        deleteIncome: {
          id: 0,
          incomeName: '',
          account: '',
          incomeType: '',
          foreignCurrencyAmount: 0,
          incomeAmount: 0,
          currencyConversion: '',
          currency: '',
          incomeDate: '',
          incomeTime: '',
          comment: '',
          category: '',

          //Recurring
          repeat: 1,
          cycle: '',
          period: '',
          numberOfCycles: 1,
          goesOffFirst: '',

          //State of Current Object
          objectState: ''
        },
        items: [],
        itemsMinusDeleted: [],
        showDataOnSide: false,
        selectedItem: 0
      }
    },
    computed: {
      listItemData() {
            return listItemFunctions.convertItemsMinusDeletedTolItem(this.itemsMinusDeleted, this.selectedItem, 'transaction')
      }
    },
    methods: {
        adjustShow: function (data) {
            this.showDataOnSide = data
        },
        dateChanged: function (date) {
            this.datePeriod = date

            this.getItems()
        },
        nextDate: function () {
            this.datePeriod = dateManager.nextDate(this.datePeriod)

            this.getItems()
        },
        previousDate: function () {
            this.datePeriod = dateManager.previousDate(this.datePeriod)

            this.getItems()
        },
      DeleteIncomeAccept: function () {
        this.deleteDialog = false

        this.deleteIncome.objectState = "Delete"

        ///Remove item from items array
        this.removeDeletedIncomesFromItems()

        widgetManager.rollbackTransactionFromIncome(this.deleteIncome)

        this.$store.commit('markIncomeAsDeleted', this.deleteIncome)
      },
      DeleteIncome: function (item) {

        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].id === item) {
            this.deleteIncome = this.items[i]
            break
          }
        }

        this.deleteDialog = true
      },
      removeDeletedIncomesFromItems: function () {
          this.itemsMinusDeleted = globalFunctions.stringify(this.items)

        for (let i = 0; i < this.itemsMinusDeleted.length; i++) {
          if (this.itemsMinusDeleted[i].objectState === 'Delete') {
            this.itemsMinusDeleted.splice(i, 1)
          }
        }
        
      },
      redirectToEditPage: function (item) {
        this.$router.push({ path: '/dashboard/edittransaction/' + item })
      },
        getitemsFromStoreAndRemoveDeletedItems: function () {
            let allItems = globalFunctions.stringify(this.$store.state.Transactions)

            for (let i = 0; i < allItems.length; i++) {
                if (allItems[i].transactionType === 'income') {
                    this.items.push(allItems[i])
                }
            }

        ///Remove deleted items from items array
        this.removeDeletedIncomesFromItems()
      },
      itemclicked: function (item) {
        this.showDataOnSide = item.show
        this.selectedItem = item.id
        },
        getItems: function () {
            this.itemsMinusDeleted = []
            let newDate = dateManager.returnDateRange(this.datePeriod)


            //Check if Search is empty  
            //if (this.search === '' || this.search === null) {
            //    //Get All Items from item
            //    this.itemsMinusDeleted = JSON.parse(JSON.stringify(this.items))
            //    return
            //}

            //Remove Transactions that does not meet search criteria 
            for (let i = 0; i < this.items.length; i++) {
                if (dateManager.isWithinDateRange(newDate.startDate, newDate.endDate, this.items[i].transactionBalances[0].transactionDate)) {
                    this.itemsMinusDeleted.push(this.items[i])
                }
            }
        },
      getSearchItems: function () {
        this.itemsMinusDeleted = []

        //Check if Search is empty  
        if (this.search === '' || this.search === null) {
          //Get All Items from item
            this.itemsMinusDeleted = globalFunctions.stringify(this.items)
          return
        }

        //Remove Transactions that does not meet search criteria 
        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].transactionName.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
            this.itemsMinusDeleted.push(this.items[i])
          }
        }
      },
      updateSearch: function (search) {
        this.search = search

        this.getSearchItems()
      },
      speedDialChanged: function (change) {
        this.speedDialOptions = globalFunctions.viewFilterSearchChanged(this.speedDialOptions, change)
      }
    },
    beforeMount() {
        this.getitemsFromStoreAndRemoveDeletedItems()
        this.getItems()

      let cat = this.$store.state.Categories
      for (let i = 0; i < cat.length; i++) {
        this.category.push(cat[i].categoryName)
      }

      let acc = this.$store.state.Accounts
      for (let i = 0; i < acc.length; i++) {
        this.account.push(acc[i].accountName)
      }
    }
    

  }
</script>
